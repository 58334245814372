import React from 'react'

import "animate.css/animate.compat.css";
import ScrollAnimation from "react-animate-on-scroll";
import "animate.css/animate.min.css";

import { HiOutlineEnvelope } from "react-icons/hi2";
import { FiPhoneCall } from "react-icons/fi";
import { BsWhatsapp } from 'react-icons/bs';


 
const Cta = () => {
  return (
    <section className='pb-[70px] w-full flex items-center justify-center'>
      <div className='container flex items-center justify-center'>
        <ScrollAnimation animateIn="fadeIn">
          <div className='cta-wrap w-full p-16 rounded-2xl flex flex-col gap-y-8 max-575:p-8'>
            <div className="heading-wrap items-center">
              <h2 className='Section-heading'>
                Get in Touch with Us!
              </h2>
              <p className='w-[80%] text-center text-lg max-575:w-full'>We're here to meet all of your wants. Our team is ready to provide you assistance, have questions, or want to know more about our services. Get in touch with us right away, and let's talk!</p>
            </div>
            <div className='cta-btns-wrap flex justify-center items-center gap-x-4 max-768:flex-wrap max-768:gap-3'>
              <a href="mailto:info@thesourcebee.com" className="btn-solid-yellow"><HiOutlineEnvelope/>Email</a>
              <a href="tel:+923163080568" className="btn-solid-yellow"><FiPhoneCall/>Phone</a>
              <a href="https://wa.me/923163080568" className="btn-outline-yellow" target='blank'><BsWhatsapp/>WhatsApp</a>
            </div>
          </div>
        </ScrollAnimation>
      </div>
    </section>
  )
}

export default Cta
