import React from 'react'
import Whatsapplogo from '../assets/WhatsApplogo.webp'

const WhatsAppFloatingBtn = () => {
    return (
        <aside className='WhatsAppFloatingBtn-wrap'>
            <a href='https://wa.me/923163080568' aria-label='Whatsapp' target='blank'>
                <img src={Whatsapplogo} alt="" className='Whatsapplogo' />
            </a>
        </aside>
    )
}

export default WhatsAppFloatingBtn
